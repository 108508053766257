@font-face {
  font-family: "Neue Haas Unica W01 Light";
  src: url("./Fonts/1491978/e5716290-d41e-4c97-a27c-7a20a46ddf45.woff2")
      format("woff2"),
    url("./Fonts/1491978/b681571f-aab2-4935-a99c-6ee1063ce638.woff")
      format("woff");
}
@font-face {
  font-family: "Neue Haas Unica W01 Medium It";
  src: url("./Fonts/1491986/2418a824-3af2-42d7-a62e-f7a77cf487d1.woff2")
      format("woff2"),
    url("./Fonts/1491986/86b76db5-9343-473a-a106-60683cec7626.woff")
      format("woff");
}
@font-face {
  font-family: "Neue Haas Unica W01 Regular";
  src: url("./Fonts/1491988/e0d80810-f7e3-4fea-8c57-ef8116f0465d.woff2")
      format("woff2"),
    url("./Fonts/1491988/27645c8a-608b-4abf-a2f1-c4407b576723.woff")
      format("woff");
}
@font-face {
  font-family: "Neue Haas Unica W01 Bold";
  src: url("Fonts/1491963/39bb8fb3-84a4-4ab8-ae45-db1834f5d807.woff2")
      format("woff2"),
    url("Fonts/1491963/96fd4fb4-75db-412f-986d-d65380ee0ccf.woff")
      format("woff");
}
